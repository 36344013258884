.footer a {
	color: #0000EE; }

.book-ad {
	padding-top: 2rem;
	padding-bottom: 2rem; }

.book-ad > a {
	color: var(--text); }


.book-ad-grid {
	background-color: antiquewhite;
	display: grid;
	align-items: center;
	justify-items: center;
	grid-template-columns:  1fr 1fr;
	grid-template-rows:  auto; }

.book-ad-grid > .text {
    font-size: 1.3rem; }

.book-ad-grid > .cover {
    height: 200px; }

//hide caption in ad
.book-ad-grid > .cover > p {
    display: none; }

.book-ad-grid > .cover > img {
	object-fit: contain;
	height: 100%;
	width: 100%;
	margin: auto auto; }

.upper_ad_line {
	font-size: 1.1rem;
	font-weight: bold;
	width: 100%;
	text-align: center;
	border-bottom: 1px solid var(--text);
	line-height: 0.1em; }

.upper_ad_line > span {
	background: var(--choice-bg);
	padding: 0 10px; }

.lower_ad_line {
	display: block;
	width: 100%;
	border-top: 1px solid var(--text);
	margin-top: 1rem; }


.nav_header {
 height: 4rem; }

.book-image {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto; }

.books-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-rows:  auto 1fr auto; }

@media screen and (min-width: 55rem) {
  .books-grid {
    grid-template-columns: 1fr 1fr; } }

.books-grid > div {
  display: grid;
  row-gap: 1em;
  grid-template-rows: auto 1fr auto;
  grid-row: span 3;
  background: var(--post-bg);
  border-radius: 0.5rem;
  padding: 2rem; }

.books-grid > div > div:last-child {
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.checkbox-lists ul {
  margin: 0;
  padding: 0;
  display: table; }

.checkbox-lists ul li {
  margin-top: 3rem;
  padding: 0;
  list-style: none;
  display: table-row; }

.checkbox-lists ul li::before {
  content: "\2705";
  display: table-cell;
  text-align: right;
  padding-right: 1em; }

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none; }


/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #f1f1f1; }


/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */;
  font-size: 20px; /* Increased text to enable scrolling */;
  padding: 0px 10px; }


/* Add an active class to the active dropdown button */
.active {
  background-color: lavender;
  color: white; }


/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: lavender;
  padding-left: 8px; }


/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px; }

